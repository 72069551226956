import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useDiscountCouponsList() {
    // Use toast
    const toast = useToast();

    const refDiscountCouponListTable = ref(null);

    // Table Handlers
    let tableColumns = [
        { key: 'code', sortable: true, label: 'Código' },
        { key: 'discount_coupon_type.name', sortable: true, label: 'Tipo de cupón' },
        { key: 'discountNumber', sortable: true, label: 'Importe del cupón' },
        { key: 'state', sortable: false, label: 'Estado' },
        { key: 'uses_limit', sortable: false, label: 'Uso / Límite' },
        { key: 'expiryDate', sortable: true, label: 'Fecha de caducidad' },
        //{ key: 'uses', sortable: true, label: 'Cantidad de veces usado' },
        { key: 'actions', label: 'Opciones' }
    ];

    const perPage = ref(10);
    const totalDiscountCoupons = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(false);

    const rangePickerFilter = ref(null);
    const typesFilter = ref(null);
    const statesFilter = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refDiscountCouponListTable.value ? refDiscountCouponListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalDiscountCoupons.value,
        }
    });

    const refetchData = () => {
        refDiscountCouponListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, rangePickerFilter, typesFilter, statesFilter], () => {
        refetchData();
    });

    const fetchDiscountCoupons = (ctx, callback) => {
        store
            .dispatch('app-discountcoupon/fetchDiscountCoupons', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,

                range: rangePickerFilter.value,
                types: typesFilter.value,
                states: statesFilter.value,
            })
            .then(response => {
                const { discountcoupons, total } = response.data;
                callback(discountcoupons);
                totalDiscountCoupons.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de cupones de descuento',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchDiscountCoupons,
        tableColumns,
        perPage,
        currentPage,
        totalDiscountCoupons,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refDiscountCouponListTable,

        refetchData,

        // Extra Filters
        rangePickerFilter,
        typesFilter,
        statesFilter
    }
}