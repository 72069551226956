import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchDiscountCoupons(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/discount-coupons`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchDiscountCoupon(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/discount-coupon/${id}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateDiscountCoupon(ctx, { id, discountCouponData }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${process.env.VUE_APP_HOST}/api/dashboard/discount-coupon/${id}/update`, discountCouponData)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        changeStateDiscountCoupon(ctx, { id, state }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${process.env.VUE_APP_HOST}/api/dashboard/discount-coupon/${id}/change-state`, { state })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteDiscountCoupon(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/discount-coupon/${id}/delete`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}