<template>
    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>FILTROS</h5>
        </b-card-header>
        <b-card-body>
            <b-row>
                <b-col
                    cols="12"
                    md="6"
                    class="mb-2"
                >
                    <label>Tipo(s)</label>
                    <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple="multiple"
                        v-model="typesFilterArray"
                        :options="typesOptions"
                        class="w-100"
                        @input="inputTypesVal()"
                    />
                </b-col>
                <b-col
                    cols="12"
                    md="6"
                    class="mb-2"
                >
                    <label>Estado(s)</label>
                    <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple="multiple"
                        v-model="statesFilterArray"
                        :options="statesOptions"
                        class="w-100"
                        @input="inputStatesVal()"
                    />
                </b-col>
                <b-col
                    cols="12"
                    md="6">
                    <label>Rango de fechas de caducidad</label>
                    <flat-pickr
                        @on-change="doSomethingOnChange"
                        v-model="rangePicker"
                        class="form-control"
                        :config="config"
                        placeholder="YYYY-MM-DD"
                    />
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>

    import { BCard, BCardHeader, BCardBody, BRow, BCol } from 'bootstrap-vue';
    import flatPickr from 'vue-flatpickr-component';
    import { Spanish } from 'flatpickr/dist/l10n/es';
    import vSelect from 'vue-select';

    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BCardHeader,
            BCardBody,
            flatPickr,
            vSelect
        },
        data (){
            return {
                config: {
                    mode: 'range',
                    locale: Spanish
                },
                minDate: null,
                maxDate: null,
                rangePicker: null,
                typesFilterArray: [],
                statesFilterArray: []
            }
        },
        props: {
            typesFilter: {
                type: [String, null],
                default: null
            },
            statesFilter: {
                type: [String, null],
                default: null
            },
            typesOptions: {
                type: Array,
                required: true
            },
            statesOptions: {
                type: Array,
                required: true
            }
        },
        methods: {
            doSomethingOnChange (){
                if (typeof this.rangePicker === 'string')
                {
                    let rangeDates = this.rangePicker.split( 'a ');
                    this.minDate = rangeDates[0];
                    this.maxDate = rangeDates[1];

                    let range = this.minDate+this.maxDate;

                    this.$emit('update:rangePickerFilter', range);
                }
            },
            inputTypesVal (){
                if (this.typesFilterArray){
                    let array = [];
                    this.typesFilterArray.map(item => {
                        array.push(item.value);
                    });
                    this.$emit('update:typesFilter', array.join(','));
                    return array;
                }
                return null;
            },
            inputStatesVal (){
                if (this.statesFilterArray){
                    let array = [];
                    this.statesFilterArray.map(item => {
                        array.push(item.value);
                    });
                    this.$emit('update:statesFilter', array.join(','));
                    return array;
                }
                return null;
            }
        }
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/vue-select.scss';
</style>