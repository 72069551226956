<template>

    <div>

		<!-- Filters -->
		<discount-coupons-list-filters
            :range-picker-filter.sync="rangePickerFilter"
            :types-filter.sync="typesFilter"
            :states-filter.sync="statesFilter"
            :types-options="typesOptions"
            :states-options="statesOptions"
        />

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0">

            <div class="m-2">

                <!-- Table Top -->
                <b-row>

                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Mostrar</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>registros</label>
                    </b-col>

                    <!-- Search -->
                    <b-col
                        cols="12"
                        md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Buscar ..."
                            />
                            <b-button
                                variant="primary"
                                :to="{ name: 'discountcoupon-create' }">
                                <span class="text-nowrap">Agregar Cupón</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

            </div>

            <b-table
                ref="refDiscountCouponListTable"
                class="position-relative"
                :items="fetchDiscountCoupons"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros coincidentes"
                :sort-desc.sync="isSortDirDesc">

                <!-- Column: Discount Number -->
                <template #cell(discountNumber)="data">
                    <span v-if="data.item.discount_coupon_type.name == 'DESCUENTO FIJO EN EL CARRITO'">S/ {{ data.item.discountNumber }}</span>
                    <span v-else>{{ data.item.discountNumber }} %</span>
                </template>

                <!-- Column: State -->
                <template #cell(state)="data">
                    <b-badge
                        pill
                        :variant="`light-${data.item.state ? 'success' : 'danger'}`"
                    >
                        {{ data.item.state ? 'HABILITADO' : 'DESHABILITADO' }}
                    </b-badge>
                </template>

                <!-- Column: Uses / Limit -->
                <template #cell(uses_limit)="data">
                    {{ data.item.usagePerUserQuantity ? data.item.usagePerUserQuantity : '&#8734;' }} / {{ data.item.usagePerCouponQuantity ? data.item.usagePerCouponQuantity : '&#8734;' }}
                </template>

                <!-- Column: Expiry Date -->
                <template #cell(expiryDate)="data">
                    {{ data.item.expiryDate ? formatDate(data.item.expiryDate) : '---' }}
                </template>

                <!-- Column: Uses -->
                <template #cell(uses)="data">0</template>

                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>

                        <b-dropdown-item :to="{ name: 'discountcoupon-edit', params: { id: data.item._id } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Editar</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="changeStateDiscountCoupon(data.item._id, true)" v-if="!data.item.state">
                            <feather-icon icon="CheckCircleIcon" />
                            <span class="align-middle ml-50">Habilitar</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="changeStateDiscountCoupon(data.item._id, false)" v-else>
                            <feather-icon icon="SlashIcon" />
                            <span class="align-middle ml-50">Deshabilitar</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="deleteDiscountCoupon(data.item._id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Eliminar</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
                    </b-col>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">

                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalDiscountCoupons"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item">

                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>

    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable } from 'bootstrap-vue';
    import vSelect from 'vue-select';
    import store from '@/store';
    import VueSweetalert2 from 'vue-sweetalert2';
    import { onUnmounted, ref } from '@vue/composition-api';
    import { avatarText } from '@core/utils/filter';
    import DiscountCouponsListFilters from './DiscountCouponsListFilters.vue';
    import useDiscountCouponsList from './useDiscountCouponsList';
    import discountCouponStoreModule from '../discountCouponStoreModule';
    import moment from 'moment';
    import axios from 'axios';
    import Vue from 'vue';
    import 'animate.css';

    Vue.use(VueSweetalert2);

    export default {
        components: {
            DiscountCouponsListFilters,

            BCard,
            BRow,
            BCol,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,

            vSelect
        },
        data () {
            return {
                typesOptions: [],
                statesOptions: [{
                    label: 'HABILITADO',
                    value: true
                }, {
                    label: 'DESHABILITADO',
                    value: false
                }]
            }
        },
        beforeMount (){
            this.getDiscountCouponTypes();
        },
        methods: {
            async getDiscountCouponTypes (){
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/discount-coupon-types/select`);
                let data = [];
                response.data.map(item => {
                    data.push({ label: item.name, value: item._id });
                });
                this.typesOptions = data;
            },
            formatDate (date){
                return moment(date).format('DD/MM/YYYY');
            }
        },
        setup() {

            // Use toast
            const toast = useToast();

            const DISCOUNT_COUPON_APP_STORE_MODULE_NAME = 'app-discountcoupon';

            // Register module
            if (!store.hasModule(DISCOUNT_COUPON_APP_STORE_MODULE_NAME)) store.registerModule(DISCOUNT_COUPON_APP_STORE_MODULE_NAME, discountCouponStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(DISCOUNT_COUPON_APP_STORE_MODULE_NAME)) store.unregisterModule(DISCOUNT_COUPON_APP_STORE_MODULE_NAME);
            });

            const {
                fetchDiscountCoupons,
                tableColumns,
                perPage,
                currentPage,
                totalDiscountCoupons,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refDiscountCouponListTable,
                refetchData,

                // Extra Filters
                rangePickerFilter,
                typesFilter,
                statesFilter
            } = useDiscountCouponsList();

            const changeStateDiscountCoupon = (id, state) => {
                store.dispatch('app-discountcoupon/changeStateDiscountCoupon', { id, state })
                    .then( (response) => {
                        refetchData();
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });
                    })
                    .catch( () => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Error al cambiar el estado del cupón de descuento',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });
                    });
            };

            const deleteDiscountCoupon = (id) => {
                Vue.swal({
                    title: '¿Estás seguro de eliminar el cupón de descuento?',
                    text: "¡Si no lo está, puede cancelar la acción!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sí, eliminar!',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    showClass: {
                        popup: 'animate__animated animate__tada'
                    },
                    buttonsStyling: false
                }).then(result => {
                    if (result.value) {
                        store.dispatch('app-discountcoupon/deleteDiscountCoupon', { id })
                            .then( (response) => {
                                refetchData();
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'success'
                                    }
                                });
                            })
                            .catch( () => {
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Error al eliminar el cupón de descuento',
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });
                            });
                    }
                });
            };

            return {

                // Sidebar

                fetchDiscountCoupons,
                tableColumns,
                perPage,
                currentPage,
                totalDiscountCoupons,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refDiscountCouponListTable,
                refetchData,

                // Extra Filters
                rangePickerFilter,
                typesFilter,
                statesFilter,

                // Filter
                avatarText,
                deleteDiscountCoupon,
                changeStateDiscountCoupon
            }
        }
    }

</script>

<style lang="scss" scoped>

    .media {
        align-items: center;
    }

    .per-page-selector {
        width: 90px;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>